import { render, staticRenderFns } from "./Update.vue?vue&type=template&id=6e43dc79&scoped=true&"
import script from "./Update.vue?vue&type=script&lang=js&"
export * from "./Update.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/figma.css?vue&type=style&index=0&id=6e43dc79&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e43dc79",
  null
  
)

export default component.exports