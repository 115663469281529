var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('div',{staticClass:"mobile-width d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"bwsTitle mobile-top"},[_vm._v(" "+_vm._s(_vm.$app_title)+" ")])]),_c('div',{staticClass:"mobile-width"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"subTitle"},[_vm._v("Daftar Logger")]),_c('div',[(_vm.role === 'is_superuser')?_c('router-link',{staticClass:"btn btn-primary float-right my-2",staticStyle:{"padding":"5px 10px"},attrs:{"to":{ name: 'AddLogger' },"type":"button"}},[_vm._v("Tambah Data")]):_vm._e()],1)]),_c('dataset',{attrs:{"ds-data":_vm.logger,"ds-sortby":_vm.sortBy,"ds-search-in":[
        'logger_ver',
        'max_channel',
        'max_analog',
        'max_digital' ]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var ds = ref.ds;
return [_c('div',{staticClass:"mb-2",attrs:{"data-page-count":ds.dsPagecount}},[_c('dataset-search',{attrs:{"ds-search-placeholder":"Search..."}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-responsive text-nowrap text-center table-borderless bg-white"},[_c('thead',{staticClass:"table-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_vm._l((_vm.cols),function(th,index){return _c('th',{key:th.field,staticClass:"thLight",class:['sort', th.sort],on:{"click":function($event){return _vm.click($event, index)}}},[_vm._v(" "+_vm._s(th.name)+" "),_c('i',{staticClass:"gg-select float-right"})])})],2)]),_c('dataset-item',{attrs:{"tag":"tbody"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
      var rowIndex = ref.rowIndex;
return [_c('tr',[_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(rowIndex + 1))]),_c('td',[_vm._v(_vm._s(row.logger_ver))]),_c('td',[_vm._v(_vm._s(row.max_channel))]),_c('td',[_vm._v(_vm._s(row.max_analog))]),_c('td',[_vm._v(_vm._s(row.max_digital))]),_c('td',[_c('span',[_c('router-link',{staticClass:"btn btn-success btn-sm mx-1",attrs:{"type":"button","to":'/logger/update/' + row.id}},[_vm._v("Edit")]),_c('button',{staticClass:"btn btn-danger btn-sm mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteData(row.id)}}},[_vm._v(" Delete ")])],1)])])]}}],null,true)})],1)])])]),_c('div',{staticClass:"d-flex flex-md-row flex-column justify-content-between align-items-center"},[_c('dataset-info',{staticClass:"mb-2 mb-md-0"}),_c('dataset-pager')],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }